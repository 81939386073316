@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kirang+Haerang&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lobster&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300&display=swap');

body {
    background-color: #212b31;
}

.App {
    display:block !important;
    min-height: 100vh !important;
    text-align: center;
    color: #FFFFFF !important;
    background-color: #212b31;
    z-index: 2 !important;
}

.centered {
    text-align: center;
}

.home-spacing {
    margin-top: 40px;
}

.p {
  color: #FFF !important;
}

.nav-spacing {
    margin-bottom: 50px;
    margin-top: 40px;
}

#page-wrap {
  overflow: auto !important;
  text-align: center;
    /*height: 100vh !important;*/
    min-width: 100vw !important;
    max-width: 100vw !important;
}

.cyphage {
    font-weight: bolder;
    color: gold;
}

hr {
    margin-top: 50px;
    border-bottom: 1px solid #FFF;
    margin-bottom: 50px;
}

.white {
    color: #FFFFFF !important;
}

.footer{
    margin-top: 50px;
    height: 200px;
    padding-top: 50px;
}

/*
.mainView::before{
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    opacity: 0.3;
    background-size: 50px; 
    background-repeat: space; 
}
*/

.mainView {
    margin-left: 1em !important;
    margin-right: 1em !important;
}

/*
.mainView {
    padding-top: 5vh !important;
}*/

.ui.grid {
  margin: unset !important;
}

h3, h4, h5, p, code, .itemDescription {
  font-family: 'Roboto', sans-serif;
  font-size: 18px;
}

h1 {
    padding-bottom: 25px !important;
}

h1, h2 {
    font-family: 'Lobster' !important;
    font-size: 40px;
    color: gold !important;  
}

p {
  text-align: left;
}

.ui.grid > * {
  padding: unset !important;
}
.pad {
  padding: 5px !important;
}

.padTop > * {
  padding-top: 20px !important;
}

.ui.segment {
  background: #000000 !important;
  border: 1px solid #6d0e05 !important;
  box-shadow: 0 4px 6px 0 #2c0a07 !important;
}

.ui.centered.grid > .column:not(.aligned):not(.justified):not(.row), .ui.centered.grid > .row > .column:not(.aligned):not(.justified), .ui.grid .centered.row > .column:not(.aligned):not(.justified) {
  text-align: unset !important;
  vertical-align: middle;
}

.ui.menu {
color: #FFF !important;
background: #212b31 !important;
border: unset !important;
box-shadow: unset !important;
padding-bottom: 5vh !important;
}

.ui.menu .item {
  color: #FFFFFF !important;
  font-family: 'Roboto', sans-serif !important;
  font-size: 20px;
}

.ui.container.wallet{
  padding-bottom: 20px !important;
}

a:hover {
    color: #FFFFFF !important;
}

.section-spacing {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
}

a.active {
    color: gold !important;
}
.ui.secondary.pointing.menu .active.item {
    border-color: gold !important;
}
.nftImgs {
    height: 125px !important;
    border-radius: 25px;
    border: 2px solid #000 !important;
}

.teamImg {
    border-radius: 25px;
    border: 2px solid #000;
}

.bar-width {
    width: 75% !important;
    margin-top: 30px !important;
    margin-bottom: 75px !important;
}

.cglogotxt {
    font-family: Lobster;
    font-size: 120px;
}

.wizard {
    padding-left: 40px;
    width: auto !important;
    height: auto !important;
}

.addressBar {
    margin-bottom: 25px !important;
    width: 95% !important;
    margin: 0 auto;
}

@media only screen and (max-width:525px){
    .cglogotxt {
        font-size: 77px;
        margin-top: 50px !important;
    }
    .ui.text.container {
        margin-left: 15px !important;
        margin-right: 15px !important;
    }
    .nftImgs {
        height: 55px !important;
        border-radius: 25px;
        border: 2px solid #000 !important;
        background-color: #000 !important;
        width: 55px !important;
    }
    .nftText {
        font-size: 12px !important;
    }
    .nftPad {
        padding: 5px !important;
    }
    .wizard {
        padding-left: 0 !important;
        width: auto !important;
        height: auto !important;
    }
    a.hash {
        font-size: 70% !important;
    }
    .addressBar {
        margin-bottom: 50px !important;
        width: 100% !important;
    }
    .addressBar .button {
        margin: 0 !important;
    }

}

a.hash {
    color: gold !important;
    text-decoration: underline !important;
}

.connectedAddress {
    font-size: 0.9em;
    text-align: center;
    color: gold;
    text-overflow: ellipsis;
    overflow: hidden;
}

.greenBoiLobster {
    color: lime !important;
    font-family: 'Lobster' !important;
    font-size: 40px !important;
}
.redBoiLobster {
    color: #FF4136 !important;
    font-family: 'Lobster' !important;
    font-size: 40px !important;
}

.greenboi {
    color: lime !important;
}

.limeyboi {
    color: lime !important;
}

.ui.fluid.dropdown {
    color: gold !important;
}

.rewardsRow {
	border-bottom: 1px solid rgba(255,255,255,0.4);
	padding-bottom: 50px !important;
	margin-top: 50px !important;
}

.rewardsBox {
	background: rgba(0,0,0,0.1);
	padding: 30px !important;
	border: 1px solid rgba(0,0,0,0.1);
}

.roadmapRow {
	padding-bottom: 20px;
	border-bottom: 1px solid rgba(255,255,255,0.2);
	margin-bottom: 25px !important;
	margin-top: 25px !important;
}