@import url(https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Kirang+Haerang&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lobster&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:wght@300&display=swap);
body {
    background-color: #212b31;
}

.App {
    display:block !important;
    min-height: 100vh !important;
    text-align: center;
    color: #FFFFFF !important;
    background-color: #212b31;
    z-index: 2 !important;
}

.centered {
    text-align: center;
}

.home-spacing {
    margin-top: 40px;
}

.p {
  color: #FFF !important;
}

.nav-spacing {
    margin-bottom: 50px;
    margin-top: 40px;
}

#page-wrap {
  overflow: auto !important;
  text-align: center;
    /*height: 100vh !important;*/
    min-width: 100vw !important;
    max-width: 100vw !important;
}

.cyphage {
    font-weight: bolder;
    color: gold;
}

hr {
    margin-top: 50px;
    border-bottom: 1px solid #FFF;
    margin-bottom: 50px;
}

.white {
    color: #FFFFFF !important;
}

.footer{
    margin-top: 50px;
    height: 200px;
    padding-top: 50px;
}

/*
.mainView::before{
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    opacity: 0.3;
    background-size: 50px; 
    background-repeat: space; 
}
*/

.mainView {
    margin-left: 1em !important;
    margin-right: 1em !important;
}

/*
.mainView {
    padding-top: 5vh !important;
}*/

.ui.grid {
  margin: unset !important;
}

h3, h4, h5, p, code, .itemDescription {
  font-family: 'Roboto', sans-serif;
  font-size: 18px;
}

h1 {
    padding-bottom: 25px !important;
}

h1, h2 {
    font-family: 'Lobster' !important;
    font-size: 40px;
    color: gold !important;  
}

p {
  text-align: left;
}

.ui.grid > * {
  padding: unset !important;
}
.pad {
  padding: 5px !important;
}

.padTop > * {
  padding-top: 20px !important;
}

.ui.segment {
  background: #000000 !important;
  border: 1px solid #6d0e05 !important;
  box-shadow: 0 4px 6px 0 #2c0a07 !important;
}

.ui.centered.grid > .column:not(.aligned):not(.justified):not(.row), .ui.centered.grid > .row > .column:not(.aligned):not(.justified), .ui.grid .centered.row > .column:not(.aligned):not(.justified) {
  text-align: unset !important;
  vertical-align: middle;
}

.ui.menu {
color: #FFF !important;
background: #212b31 !important;
border: unset !important;
box-shadow: unset !important;
padding-bottom: 5vh !important;
}

.ui.menu .item {
  color: #FFFFFF !important;
  font-family: 'Roboto', sans-serif !important;
  font-size: 20px;
}

.ui.container.wallet{
  padding-bottom: 20px !important;
}

a:hover {
    color: #FFFFFF !important;
}

.section-spacing {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
}

a.active {
    color: gold !important;
}
.ui.secondary.pointing.menu .active.item {
    border-color: gold !important;
}
.nftImgs {
    height: 125px !important;
    border-radius: 25px;
    border: 2px solid #000 !important;
}

.teamImg {
    border-radius: 25px;
    border: 2px solid #000;
}

.bar-width {
    width: 75% !important;
    margin-top: 30px !important;
    margin-bottom: 75px !important;
}

.cglogotxt {
    font-family: Lobster;
    font-size: 120px;
}

.wizard {
    padding-left: 40px;
    width: auto !important;
    height: auto !important;
}

.addressBar {
    margin-bottom: 25px !important;
    width: 95% !important;
    margin: 0 auto;
}

@media only screen and (max-width:525px){
    .cglogotxt {
        font-size: 77px;
        margin-top: 50px !important;
    }
    .ui.text.container {
        margin-left: 15px !important;
        margin-right: 15px !important;
    }
    .nftImgs {
        height: 55px !important;
        border-radius: 25px;
        border: 2px solid #000 !important;
        background-color: #000 !important;
        width: 55px !important;
    }
    .nftText {
        font-size: 12px !important;
    }
    .nftPad {
        padding: 5px !important;
    }
    .wizard {
        padding-left: 0 !important;
        width: auto !important;
        height: auto !important;
    }
    a.hash {
        font-size: 70% !important;
    }
    .addressBar {
        margin-bottom: 50px !important;
        width: 100% !important;
    }
    .addressBar .button {
        margin: 0 !important;
    }

}

a.hash {
    color: gold !important;
    text-decoration: underline !important;
}

.connectedAddress {
    font-size: 0.9em;
    text-align: center;
    color: gold;
    text-overflow: ellipsis;
    overflow: hidden;
}

.greenBoiLobster {
    color: lime !important;
    font-family: 'Lobster' !important;
    font-size: 40px !important;
}
.redBoiLobster {
    color: #FF4136 !important;
    font-family: 'Lobster' !important;
    font-size: 40px !important;
}

.greenboi {
    color: lime !important;
}

.limeyboi {
    color: lime !important;
}

.ui.fluid.dropdown {
    color: gold !important;
}

.rewardsRow {
	border-bottom: 1px solid rgba(255,255,255,0.4);
	padding-bottom: 50px !important;
	margin-top: 50px !important;
}

.rewardsBox {
	background: rgba(0,0,0,0.1);
	padding: 30px !important;
	border: 1px solid rgba(0,0,0,0.1);
}

.roadmapRow {
	padding-bottom: 20px;
	border-bottom: 1px solid rgba(255,255,255,0.2);
	margin-bottom: 25px !important;
	margin-top: 25px !important;
}
/*@keyframes flicker {
    0% {
      opacity: 0.27861;
    }
    5% {
      opacity: 0.34769;
    }
    10% {
      opacity: 0.23604;
    }
    15% {
      opacity: 0.90626;
    }
    20% {
      opacity: 0.18128;
    }
    25% {
      opacity: 0.83891;
    }
    30% {
      opacity: 0.65583;
    }
    35% {
      opacity: 0.67807;
    }
    40% {
      opacity: 0.26559;
    }
    45% {
      opacity: 0.84693;
    }
    50% {
      opacity: 0.96019;
    }
    55% {
      opacity: 0.08594;
    }
    60% {
      opacity: 0.20313;
    }
    65% {
      opacity: 0.71988;
    }
    70% {
      opacity: 0.53455;
    }
    75% {
      opacity: 0.37288;
    }
    80% {
      opacity: 0.71428;
    }
    85% {
      opacity: 0.70419;
    }
    90% {
      opacity: 0.7003;
    }
    95% {
      opacity: 0.36108;
    }
    100% {
      opacity: 0.24387;
    }
  }
  @keyframes textShadow {
    0% {
      text-shadow: 0.4389924193300864px 0 1px rgba(0,30,255,0.5), -0.4389924193300864px 0 1px rgba(0, 190, 79, 0.3), 0 0 3px;
    }
    5% {
      text-shadow: 2.7928974010788217px 0 1px rgba(0,30,255,0.5), -2.7928974010788217px 0 1px rgba(0, 190, 79, 0.3), 0 0 3px;
    }
    10% {
      text-shadow: 0.02956275843481219px 0 1px rgba(0,30,255,0.5), -0.02956275843481219px 0 1px rgba(0, 190, 79, 0.3), 0 0 3px;
    }
    15% {
      text-shadow: 0.40218538552878136px 0 1px rgba(0,30,255,0.5), -0.40218538552878136px 0 1px rgba(0, 190, 79, 0.3), 0 0 3px;
    }
    20% {
      text-shadow: 3.4794037899852017px 0 1px rgba(0,30,255,0.5), -3.4794037899852017px 0 1px rgba(0, 190, 79, 0.3), 0 0 3px;
    }
    25% {
      text-shadow: 1.6125630401149584px 0 1px rgba(0,30,255,0.5), -1.6125630401149584px 0 1px rgba(0, 190, 79, 0.3), 0 0 3px;
    }
    30% {
      text-shadow: 0.7015590085143956px 0 1px rgba(0,30,255,0.5), -0.7015590085143956px 0 1px rgba(0, 190, 79, 0.3), 0 0 3px;
    }
    35% {
      text-shadow: 3.896914047650351px 0 1px rgba(0,30,255,0.5), -3.896914047650351px 0 1px rgba(0, 190, 79, 0.3), 0 0 3px;
    }
    40% {
      text-shadow: 3.870905614848819px 0 1px rgba(0,30,255,0.5), -3.870905614848819px 0 1px rgba(0, 190, 79, 0.3), 0 0 3px;
    }
    45% {
      text-shadow: 2.231056963361899px 0 1px rgba(0,30,255,0.5), -2.231056963361899px 0 1px rgba(0, 190, 79, 0.3), 0 0 3px;
    }
    50% {
      text-shadow: 0.08084290417898504px 0 1px rgba(0,30,255,0.5), -0.08084290417898504px 0 1px rgba(0, 190, 79, 0.3), 0 0 3px;
    }
    55% {
      text-shadow: 2.3758461067427543px 0 1px rgba(0,30,255,0.5), -2.3758461067427543px 0 1px rgba(0, 190, 79, 0.3), 0 0 3px;
    }
    60% {
      text-shadow: 2.202193051050636px 0 1px rgba(0,30,255,0.5), -2.202193051050636px 0 1px rgba(0, 190, 79, 0.3), 0 0 3px;
    }
    65% {
      text-shadow: 2.8638780614874975px 0 1px rgba(0,30,255,0.5), -2.8638780614874975px 0 1px rgba(0, 190, 79, 0.3), 0 0 3px;
    }
    70% {
      text-shadow: 0.48874025155497314px 0 1px rgba(0,30,255,0.5), -0.48874025155497314px 0 1px rgba(0, 190, 79, 0.3), 0 0 3px;
    }
    75% {
      text-shadow: 1.8948491305757957px 0 1px rgba(0,30,255,0.5), -1.8948491305757957px 0 1px rgba(0, 190, 79, 0.3), 0 0 3px;
    }
    80% {
      text-shadow: 0.0833037308038857px 0 1px rgba(0,30,255,0.5), -0.0833037308038857px 0 1px rgba(0, 190, 79, 0.3), 0 0 3px;
    }
    85% {
      text-shadow: 0.09769827255241735px 0 1px rgba(0,30,255,0.5), -0.09769827255241735px 0 1px rgba(0, 190, 79, 0.3), 0 0 3px;
    }
    90% {
      text-shadow: 3.443339761481782px 0 1px rgba(0,30,255,0.5), -3.443339761481782px 0 1px rgba(0, 190, 79, 0.3), 0 0 3px;
    }
    95% {
      text-shadow: 2.1841838852799786px 0 1px rgba(0,30,255,0.5), -2.1841838852799786px 0 1px rgba(0, 190, 79, 0.3), 0 0 3px;
    }
    100% {
      text-shadow: 2.6208764473832513px 0 1px rgba(0,30,255,0.5), -2.6208764473832513px 0 1px rgba(0, 190, 79, 0.3), 0 0 3px;
    }
  }
  .crt::after {
    content: " ";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(18, 16, 16, 0.1);
    opacity: 0;
    z-index: 2;
    pointer-events: none;
    animation: flicker 0.5s infinite;
  }
  .crt::before {
    content: " ";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: linear-gradient(rgba(18, 16, 16, 0) 50%, rgba(0, 0, 0, 0.25) 50%), linear-gradient(90deg, rgba(255, 0, 0, 0.06), rgba(0, 255, 0, 0.02), rgba(0, 0, 255, 0.06));
    z-index: 2;
    background-size: 100% 2px, 3px 100%;
    pointer-events: none;
  }
  .crt {
    animation: textShadow 1.6s infinite;
    filter: brightness(1.3);
  }*/
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

